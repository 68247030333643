<script setup>
import texts from "~/configs/texts";

const slides = useSlides();

const refControl = ref();
const refSlideNavigation = ref();

onMounted(() => slides.initSlides(refControl, refSlideNavigation))

</script>

<template>
  <div>
    <SubContentHeader centered>Leasing VEHIS a inne formy finansowania samochodu</SubContentHeader>
    <SlideControl ref="refControl" class="mt-4"
                  @initiated="slides.setSlides"
                  @swipeEnd="(slide) => slides.setSlide(slide)">
      <SlideItem v-for="(type, index) in texts.leasingFeatures.types">
        <div class="bg-white rounded-lg p-6 h-[328px] border-gray-400 border">
          <div>{{ type }}</div>
          <div class="pt-4 text-sm font-extralight">{{ texts.leasingFeatures.texts[index] }}</div>
        </div>
      </SlideItem>
    </SlideControl>
    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items="slides.slidesCount.value"
                     @changed="(slide) => slides.scrollToSlide(slide)"
    />
  </div>
</template>
